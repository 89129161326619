<template>
  <div class="confirm_an_order">
    <!-- <div class="address" @click="$router.push('/Address')"> -->
    <div class="address" v-if="takeTheir || $store.state.App">
      <svg-icon icon-class="address_icon" class="address_more" />
      <div>
        <b>自提点地址：{{ tzInfo.userDetailEntity.getGoodsAddress }}</b>
      </div>
      <p class="more" @click="switchAddress" v-if="$store.state.App">
        切换<svg-icon icon-class="home_more_icon" class="svg_more" />
      </p>
    </div>
    <div
      class="address"
      @click="$router.push('/userData/address?isConfirmAnOrder=1')"
    >
      <svg-icon icon-class="address_icon" class="address_more" v-if="user" />
      <div>
        <b>{{
          user
            ? user.province + user.city + user.county + user.detailAddress
            : "收货地址（必填）"
        }}</b>
        <p v-if="user">
          {{ user.nameAddress }}&nbsp;&nbsp;{{ user.phoneAddress2 }}
        </p>
        <p v-else>一件代发商品将直接寄到此收货地址，请规范填写</p>
      </div>
      <svg-icon icon-class="home_more_icon" class="svg_more" />
    </div>

    <div class="goods_box" v-for="(list, index) in orderList" :key="index">
      <div class="goods_list">
        <div class="store" v-if="!list.integral">
          <svg-icon icon-class="store_icon" class="svg_store" />
          <b>{{ list.storeName }}</b>
          <!-- <svg-icon icon-class="home_more_icon" class="svg_more" /> -->
          <span>{{ list.freeShipping ? "包邮" : "自提" }}</span>
        </div>
        <div
          class="goods"
          v-for="(item, i) in list.orderCardListItems"
          :key="i"
        >
          <div>
            <img alt="" v-lazy="item.filePath" />
            <div class="details">
              <h4>
                <span v-if="item.storeTastingActivityInfoEntity"
                  >[新人尝鲜价]
                </span>
                {{ item.commodityName }}
              </h4>
              <!-- <p>
                  归属品牌：<span>{{ item.brandName }}</span>
                </p> -->
              <p>
                商品规格：<span>{{ item.specsParameter }}</span>
              </p>
              <span v-if="item.attributeId" class="attribute">{{
                item.attributeName
              }}</span>
              <div class="price">
                <span>×{{ item.quantity }}</span>
                <b v-if="!item.integral"
                  ><span>￥</span>{{ item.retailPrice | Price }}</b
                ><b v-else style="color: #fb4e10">{{ item.integral }}积分</b>
              </div>
            </div>
          </div>
          <p class="tips">
            <span v-if="!item.deduction"
              ><svg-icon icon-class="note" />该商品不可抵扣平台积分</span
            >
          </p>
        </div>
        <div class="message">
          <div>
            <p>商品总金额</p>
            <b v-if="!list.integral"
              ><span>￥</span>{{ list.amount | Price }}</b
            >
            <b v-else style="color: #fb4e10; font-size: 14px"
              >{{ list.integral }}积分</b
            >
          </div>
          <div>
            <p>运费</p>
            <span>0.00</span>
          </div>
          <div><p>买家留言</p></div>
          <van-field
            v-model="list.message"
            rows="2"
            :autosize="{ maxHeight: 80 }"
            type="textarea"
            maxlength="500"
            placeholder="若有特殊要求，需和商家协商一致，否则填写内容将视为无效"
            show-word-limit
          />
        </div>
      </div>
    </div>
    <!-- v-if="bank.length" -->
    <div
      class="integral"
      @click="
        allIntegral && !orderList[0].integral ? (integralShow = true) : ''
      "
    >
      <span>积分抵扣</span>
      <p v-if="!orderList[0].integral">
        <span v-if="selectIntegral"
          >使用{{ selectIntegral }}积分抵扣{{
            (selectIntegral / 100).toFixed(2)
          }}元</span
        >
        <span v-else-if="!allIntegral">0积分可用</span>
        <span v-else-if="!selectIntegral">不使用积分</span>
        <svg-icon icon-class="arrow_right" v-if="allIntegral" />
      </p>
      <b v-else>{{ orderList[0].integral }}积分</b>
    </div>
    <van-action-sheet
      v-model="integralShow"
      title="积分选择"
      @click-overlay="closeIntegral"
      @cancel="closeIntegral"
      @open="openIntegral"
    >
      <div class="title">
        <div>
          <p>订单金额：</p>
          <h3><span>￥</span>{{ allPrice2 }}</h3>
        </div>
        <div>
          <p>积分抵扣金额：</p>
          <h3><span>￥</span>{{ (selectIntegral / 100).toFixed(2) }}</h3>
        </div>
      </div>
      <div class="content">
        <van-checkbox-group v-model="result" @change="radioChange">
          <div class="use_integral" v-for="list in bank" :key="list.bankId">
            <van-cell clickable :label="list.integral ? '' : '0积分可用'">
              <template #title>
                <span>{{
                  list.bankId === "0" ? "正品汇平台积分" : `${list.bankName}VIP`
                }}</span>
                <!-- <p style="font-size: 12px; color: #f98f10">
                  订单金额每满100元可使用积分抵扣10元
                </p> -->
                <p v-show="list.disabled && list.bankId === '0'">
                  <span
                    style="text-decoration: underline; color: #f98f10"
                    @click="ruleShow = true"
                    >积分使用规则</span
                  >
                </p>
              </template>
              <template #right-icon>
                <van-checkbox
                  :name="list.bankId"
                  v-model="list.isSelect"
                  :disabled="list.disabled"
                  @click="list.disabled ? '' : (list.isSelect = !list.isSelect)"
                  @change="checkboxChange(list)"
                />
              </template>
            </van-cell>
            <div class="modify" :class="list.isSelect ? '' : 'close'">
              <div class="switchover">
                <span
                  :class="list.priceOrIntegral ? 'select' : ''"
                  @click="switchover(list, 1)"
                  >使用积分</span
                >
                <span>丨</span>
                <span
                  :class="list.priceOrIntegral ? '' : 'select'"
                  @click="switchover(list, 0)"
                  >抵扣金额</span
                >
              </div>
              <div class="modify_input">
                <b v-show="!list.priceOrIntegral">￥</b>
                <van-field
                  v-model="list.Integral"
                  type="digit"
                  @input="integralChange(list)"
                  @blur="integralBlur(list)"
                  @focus="integralFocus(list)"
                  v-show="list.priceOrIntegral"
                  class="integral_input"
                />
                <van-field
                  v-model="list.Price"
                  type="number"
                  @input="priceChange(list)"
                  @blur="priceBlur(list)"
                  @focus="priceFocus(list)"
                  v-show="!list.priceOrIntegral"
                />
                <span v-show="list.priceOrIntegral"
                  >抵扣金额￥{{ (list.Integral / 100).toFixed(2) }}</span
                >
                <span v-show="!list.priceOrIntegral"
                  >抵扣积分{{ Math.round(list.Price * 100) }}</span
                >
              </div>
              <div class="tips">
                <span>总积分{{ list.integral }}，</span>
                <span
                  v-if="list.bankId === '0'"
                  style="text-decoration: underline"
                  @click="ruleShow = true"
                  >使用规则</span
                >
                <span v-else>(兑换比例：100积分=1元)</span>
              </div>
            </div>
          </div>
          <van-cell title="不使用积分" clickable class="do_not_use_integral">
            <template #right-icon>
              <van-checkbox :name="-1" v-model="radio" @click="noneCheckbox" />
              <!-- <van-radio :name="0" checked-color="#fff" /> -->
            </template>
          </van-cell>
        </van-checkbox-group>
      </div>
      <div class="footer van-hairline--top">
        <van-button round block color="#009A4D" @click="confirm"
          >确定</van-button
        >
      </div>
    </van-action-sheet>
    <van-submit-bar
      class="van-hairline--top"
      :price="orderList[0].integral ? orderList[0].integral * 100 : allPrice"
      :decimal-length="orderList[0].integral ? 0 : 2"
      :currency="orderList[0].integral ? '' : '¥'"
      :suffix-label="orderList[0].integral ? '积分' : ''"
      :label="`${allSpecies}种共${allNum}件，总计：`"
      :button-text="orderList[0].integral ? '去兑换' : '去付款'"
      text-align="left"
      @submit="confirmTakeTheir"
      :button-color="
        orderList[0].integral
          ? 'linear-gradient(180deg, #F91C12 0%, #FB4E10 100%) '
          : '#009A4D'
      "
    >
    </van-submit-bar>
    <van-overlay
      :show="show"
      z-index="10000"
      style="display: flex; align-items: center; justify-content: center"
    >
      <van-loading :vertical="true" color="#fff">生成订单中...</van-loading>
    </van-overlay>
    <van-overlay
      :show="ruleShow"
      @click="ruleShow = false"
      z-index="9999999"
      :lock-scroll="false"
    >
      <div class="wrapper" @click.stop>
        <div class="title">
          <span>使用规则</span>
          <svg-icon icon-class="close" @click="ruleShow = false" />
        </div>
        <div class="content">
          <p v-for="item in integralRule" :key="item.id">
            🌟单笔订单累计满{{ item.amount }}元立减{{
              item.integral / 100
            }}元（{{ item.integral }}积分直接抵扣）
          </p>
          <p>以上积分活动最终解释权归正品汇平台所有。</p>
        </div>
      </div>
    </van-overlay>
    <van-popup
      v-model="takeTheirShow"
      position="bottom"
      class="takeTheir_popup"
    >
      <div class="title">
        提示<svg-icon icon-class="close" @click="takeTheirShow = false" />
      </div>
      <div class="content">
        <div class="tips">
          <p>订单包含自提商品，需要您到店自提，请确认提货信息</p>
          <p>预计最晚{{ takeTheirTime }}可自提</p>
          <p>（注：下单后2个工作日内配送，配送当天提前1个小时电话联系用户）</p>
        </div>
        <div class="info">
          <h3>
            提货人：{{ user ? user.nameAddress : "" }}&nbsp;&nbsp;{{
              user ? user.phoneAddress : ""
            }}
          </h3>
          <h3>自提点：{{ tzInfo.userDetailEntity.getGoodsName }}</h3>
          <div>
            <span>店长：</span>
            <p>{{ tzInfo.userEntity.actualName }}</p>
          </div>
          <!-- <div>
            <span>电话：</span>
            <p>{{ tzInfo.userEntity.telNum }}</p>
          </div> -->
          <div>
            <span>自提地址：</span>
            <p>
              {{ tzInfo.userDetailEntity.getGoodsAddress }}
            </p>
          </div>
        </div>
      </div>
      <div class="btn">
        <van-button
          round
          color="#F4F5F9"
          style="margin-right: 12px"
          @click="takeTheirShow = false"
          >取消</van-button
        ><van-button
          round
          color="#009A4D"
          style="margin-left: 12px"
          @click="onSubmit"
          >确认支付</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script
  type="text/javascript"
  charset="UTF-8"
  src="https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js"
></script>
<script>
import { mapState } from "vuex";
import { getOpenId } from "@/utils/auth";
import {
  btoa,
  twoDaysLater,
  getAvailableCoupon,
  preferential,
} from "@/utils/validate";

export default {
  name: "confirm-an-order",
  data() {
    return {
      orderList: [],
      bank: [],
      user: null,
      goodsName: "",
      show: false,
      pinkage: false, //包邮
      takeTheir: false, //自提
      integralShow: false,
      result: [],
      radio: false,
      priceOrIntegral: 1,
      allIntegral: 0,
      allPrice2: 0, //总价
      Integral: 0, // 用户填写积分
      Price: 0, // 用户填写价格
      selectIntegral: 0,
      integralCache: {
        radio: false,
        result: [],
        selectIntegral: 0,
        selectIntegral2: 0,
      },
      // integralState: 0,
      // memberPointsShow: true,
      ruleShow: false,
      stockpileBank: "",
      selectIntegral2: 0, //判断是否第一次获取input焦点
      takeTheirShow: false,
      takeTheirTime: "",
      nonedeductible: 0, //不可抵扣
    };
  },
  filters: {
    Price(num) {
      // return num.toFixed(2);
      if (num === null) {
        return "-";
      } else if (String(Number(num)).indexOf(".")) {
        // return num;
        // console.log(num);
        return num.toFixed(2);
      } else {
        return num;
      }
    },
  },
  computed: {
    ...mapState("user", [
      "userInfo",
      "tzInfo",
      "maxIntegralBank",
      "integralRule",
    ]),
    allSpecies() {
      var allSpecies = 0;
      for (let shop of this.orderList) {
        for (let goods of shop.orderCardListItems) {
          allSpecies += 1;
        }
      }
      return allSpecies;
    },
    allPrice() {
      var allPrice = 0;
      this.nonedeductible = 0;
      for (let shop of this.orderList) {
        for (let goods of shop.orderCardListItems) {
          allPrice += goods.quantity * goods.retailPrice;
          if (!goods.deduction) {
            this.nonedeductible += goods.quantity * goods.retailPrice;
          }
        }
      }
      this.allPrice2 = allPrice.toFixed(2);
      allPrice = Math.round(allPrice * 100) - this.selectIntegral;
      return allPrice;
    },
    allNum() {
      var allNum = 0;
      for (let shop of this.orderList) {
        for (let goods of shop.orderCardListItems) {
          allNum += goods.quantity;
        }
      }
      return allNum;
    },
    //抵扣金额
    discount() {
      let discount = this.Integral / 100;
      discount = discount.toFixed(2);
      return discount;
    },
    //抵扣积分
    discountPoints() {
      return Math.round(this.Price * 100);
    },
  },
  watch: {
    bank: {
      handler(val) {
        // console.log(val);
      },
      deep: true,
    },
  },
  created() {
    if (sessionStorage.getItem(window.btoa("order"))) {
      this.orderList = btoa(sessionStorage.getItem(window.btoa("order")), 0);

      this.getUserAddress();
      this.getBankInfo();
      this.takeTheirTime = twoDaysLater(new Date().getTime());

      this.orderList.forEach((list) => {
        if (list.freeShipping) {
          this.pinkage = true;
        } else {
          this.takeTheir = true;
        }
      });
      console.log(this.orderList);
    } else {
      this.$router.push("/");
    }
  },
  mounted() {},
  beforeDestroy() {},

  methods: {
    bankData() {
      // this.test = [
      //   {
      //     id: "B8D8D4234C7D4339BA3BC7CBC389D628",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 200,
      //     amount: 20,
      //     delete: false,
      //   },
      //   {
      //     id: "8AF173CAEC18446296CF358267E02494",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 500,
      //     amount: 50,
      //     delete: false,
      //   },
      //   {
      //     id: "E85A97CC2BAF49ABA99EEDA365C000F6",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 100,
      //     amount: 100,
      //     delete: false,
      //   },
      //   {
      //     id: "F0229FD110CB465AB1E09C13742E2B81",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 3000,
      //     amount: 200,
      //     delete: false,
      //   },
      //   {
      //     id: "F0229FD110CB465AB1E09C13742W2B81",
      //     createTime: 1681971770000,
      //     updateTime: 1681971770000,
      //     integral: 5000,
      //     amount: 300,
      //     delete: false,
      //   },
      // ];
      this.bank.forEach((bank) => {
        this.$set(bank, "allIntegral", 0);
        this.$set(bank, "allPriceIntegral", 0);
        this.$set(bank, "Integral", 0);
        this.$set(bank, "Price", 0);
        this.$set(bank, "selectIntegral", 0);
        this.$set(bank, "isSelect", false);
        this.$set(bank, "disabled", false);
        this.$set(bank, "priceOrIntegral", 1);
        this.allIntegral += bank.integral;

        if (bank.bankId === "0") {
          const { availableCoupon } = getAvailableCoupon(
            this.integralRule,
            this.allPrice2 - this.nonedeductible,
            bank.integral
          );
          this.availableCoupon = availableCoupon;

          availableCoupon
            ? bank.integral <= availableCoupon.integral
              ? (bank.allPriceIntegral = bank.integral / 100)
              : (bank.allPriceIntegral = availableCoupon.integral / 100)
            : (bank.allPriceIntegral = 0);
        } else {
          if (this.allPrice2 >= 300) {
            bank.allPriceIntegral = 5;
          } else if (this.allPrice2 >= 200) {
            bank.allPriceIntegral = 3;
          } else {
            bank.allPriceIntegral = 1;
          }
        }

        bank.allIntegral = Math.trunc(bank.integral / 100); //总积分
      });

      // try {
      this.bank.forEach((bank, i) => {
        if (bank.integral == "null") bank.integral = 0;
        if (bank.integral === 0) {
          bank.disabled = true;
          return;
        }
        if (
          bank.bankId === "0" &&
          this.allPrice2 - this.nonedeductible >= this.availableCoupon.amount
        ) {
          if (bank.allPriceIntegral * 100 >= bank.integral) {
            bank.Integral = bank.integral;
            bank.Price = (bank.integral / 100).toFixed(2);
          } else {
            bank.Integral = bank.allPriceIntegral * 100;
            bank.Price = bank.allPriceIntegral.toFixed(2);
          }

          if (bank.integral) {
            this.result.push(bank.bankId);
            bank.isSelect = true;
          }

          bank.selectIntegral = bank.Integral;
          this.selectIntegral = bank.Integral;
          // throw error;
        } else if (bank.bankId === "06D7C96C4ACE485DBDE187A9A0A6FD7F") {
          if (bank.integral < 0) bank.integral = 0;
          if (bank.integral === 0) return;
          // console.log(this.bank[i-1]);
          this.result.push(bank.bankId);
          bank.isSelect = true;
          if (Math.round(this.allPrice2 * 100) > bank.integral) {
            //总价大于总积分this.selectIntegral
            if (bank.integral > this.selectIntegral) {
              bank.Integral = bank.integral;
              bank.Price = (bank.integral / 100).toFixed(2);
            } else {
              bank.Integral = bank.integral;
              bank.Price = (bank.integral / 100).toFixed(2);
            }
          } else {
            bank.Integral =
              Math.round(this.allPrice2 * 100) - this.selectIntegral;
            bank.Price = (
              (Math.round(this.allPrice2 * 100) - this.selectIntegral) /
              100
            ).toFixed(2);

            // this.Integral = Math.round(this.allPrice2 * 100) - 1;
            // this.Price = Number(this.allPrice2).toFixed(2) - 0.01;
          }

          bank.selectIntegral = bank.Integral;
          this.selectIntegral += bank.Integral;
        } else {
          bank.disabled = true;
        }
      });

      if (
        !this.bank.find((item) => {
          return item.isSelect;
        })
      ) {
        this.radio = true;
        this.result = [-1];
      }
      this.selectIntegral2 = 1;

      // } catch (e) {
      //   return;
      // }

      return;
      if (!data.data.data) return; //无积分
      this.allIntegral = data.data.data.integral;
      if (this.integralState === 1) {
        if (this.allIntegral < 0) this.allIntegral = 0;
        if (this.allIntegral !== 0) {
          this.radio = 1;
        }
        if (this.allPrice2 * 100 > this.allIntegral) {
          //总价大于总积分
          this.Integral = this.allIntegral;
          let a = this.allIntegral / 100;
          this.Price = a.toFixed(2);
        } else {
          this.Integral = Math.round(this.allPrice2 * 100);
          this.Price = Number(this.allPrice2).toFixed(2);
          // this.Integral = Math.round(this.allPrice2 * 100) - 1;
          // this.Price = Number(this.allPrice2).toFixed(2) - 0.01;
        }
      } else if (this.integralState === 2) {
        this.radio = 1;
        let all = Math.trunc(this.allIntegral / 100); //总积分
        let integral = Math.trunc(this.allPrice2 / 100); //金额可用积分

        if (this.allPrice2 >= 300) {
          integral = 5;
        } else if (this.allPrice2 >= 200) {
          integral = 3;
        }

        if (integral * 100 >= this.allIntegral) {
          this.Integral = all * 100;
          this.Price = (all * 10).toFixed(2);
        } else {
          this.Integral = integral * 100;
          this.Price = (integral * 10).toFixed(2);
        }
      }

      this.selectIntegral = this.Integral;
    },
    async getUserAddress() {
      const data = await this.$API.user.queryAddress({
        pageNum: 1,
        pageSize: 1,
      });
      this.user = data.data.data[0];
      if (this.user) {
        this.user.phoneAddress2 =
          this.user.phoneAddress.substr(0, 3) +
          "****" +
          this.user.phoneAddress.substr(7);
      }
    },
    async getBankInfo() {
      const data = await this.$API.user.CusBankFilePathList({
        object: {
          id: this.userInfo.id,
        },
        pageNum: 0,
        pageSize: 0,
      });
      if (!data.data.data) return;
      this.bank = data.data.data;
      this.bankData();
    },

    confirmTakeTheir() {
      if (process.env.NODE_ENV === "production") {
        if (typeof WeixinJSBridge == "undefined" && !this.$store.state.App) {
          this.Toast.fail("暂未开通微信外支付，请在微信内支付");
          return;
        }
      }
      if (!this.user) {
        this.Toast.fail("请添加收货地址");
        return;
      }
      if (this.takeTheir) {
        this.takeTheirShow = true;
      } else {
        this.onSubmit();
      }
    },
    async onSubmit() {
      // this.show = true;
      if (this.orderList[0].integral) {
        this.toExchange();
        return;
      }

      let bankId = "";
      let bankIntegralAmount = 0;
      let integralAmount = 0;
      this.bank.forEach((bank) => {
        if (
          bank.bankId === "06D7C96C4ACE485DBDE187A9A0A6FD7F" &&
          bank.isSelect
        ) {
          bankId = bank.bankId;
          bankIntegralAmount = bank.selectIntegral / 100;
        } else if (bank.bankId === "0" && bank.isSelect) {
          integralAmount = bank.selectIntegral / 100;
        }
      });

      let totalQuantity = 0;
      let id = [];
      let storeId = [];
      let senterpriseId = [];
      let remarks = [];
      let freeShipping = [];
      let type;
      let totalReferencePrice = 0;
      this.orderList.forEach((store) => {
        store.totalReferencePrice = 0;
        storeId.push(store.storeId);
        remarks.push(store.message);
        // freeShipping.push(true);
        freeShipping.push(store.freeShipping);
        senterpriseId.push(store.orderCardListItems[0].enterpriseId);

        (store.item = {
          attributeId: [], //属性Id
          attributeName: [], //属性名称
          barcode: [], //条形码
          commId: [], //商品ID
          commodityCode: [], //单品码
          enterpriseId: [], //企业ID
          quantity: [], //销售数量
          referencePrice: [], //团长金额
          sellingPrice: [], //销售金额
          type: [], //活动类型 0-普通价，1-新人尝鲜价，2-秒杀价
          typeId: [], //活动id
        }),
          store.orderCardListItems.forEach((goods) => {
            this.goodsName += goods.commodityName + "　";
            totalQuantity += goods.quantity;
            id.push(goods.id);
            store.item.barcode.push(goods.barcode); //条形码
            store.item.commId.push(goods.commId); //商品ID
            store.item.commodityCode.push(""); //单品码
            store.item.enterpriseId.push(goods.enterpriseId); //企业ID
            store.item.quantity.push(goods.quantity); //销售数量
            store.item.sellingPrice.push(
              Number((goods.retailPrice * goods.quantity).toFixed(2))
            ); //销售金额
            store.item.referencePrice.push(Number(goods.tradePrice)); //团长销售金额
            //如果是尝鲜商品并且不是尝鲜用户
            store.totalReferencePrice += Number(
              (goods.tradePrice * goods.quantity).toFixed(2)
            );
            if (goods.type === 1 && !goods.storeTastingActivityInfoEntity) {
              if (preferential(goods)) {
                store.item.type.push(4); //活动类型
              } else {
                store.item.type.push(0); //活动类型
              }
              store.item.typeId.push(""); //活动id
            } else {
              store.item.type.push(goods.type); //活动类型
              store.item.typeId.push(goods.typeId); //活动id
            }

            if (goods.type === 2) type = 2;
            if (goods.attributeId) {
              store.item.attributeName.push(
                goods.attributeName.split("/").join(",")
              ); //属性名称
              store.item.attributeId.push(
                goods.attributeId.split("/").join(",")
              ); //属性Id
            } else {
              store.item.attributeName.push(""); //属性名称
              store.item.attributeId.push(""); //属性Id
            }
          });
        totalReferencePrice += store.totalReferencePrice;
      });

      let orderCommodityItems = this.orderList.map((item) => {
        return item.item;
      });
      // console.log();
      // let object = {
      //   address: this.user.detailAddress, //详细地址
      //   addressId: "", //地址ID
      //   bankId: bankId, //使用积分银行ID
      //   bankIntegralAmount: bankIntegralAmount, //银行积分抵扣金额
      //   city: this.user.city, //城市
      //   consignee: this.user.nameAddress, //收货联系人
      //   country: "", //国家
      //   county: this.user.county, //区县
      //   freeShipping: freeShipping,
      //   id: id, //购物车列表ID
      //   integralAmount: integralAmount, //平台积分金额
      //   memberName: this.userInfo.username, //会员名称
      //   memberUuid: this.userInfo.id, //会员ID
      //   orderCommodityItems: orderCommodityItems,
      //   // penterpriseId: "string",//
      //   province: this.user.province, //省份
      //   receivingUnit: "", //收货人单位
      //   remarks: remarks, //备注
      //   senterpriseId: senterpriseId, //
      //   specialPrice: 0, //优惠金额
      //   storeId: storeId, //店铺ID
      //   telephone: this.user.phoneAddress, //收货人联系电话
      //   totalAmount: this.allPrice / 100, //订单总金额
      //   // totalAmount: 0.01, //订单总金额
      //   totalQuantity: totalQuantity, //订单总数
      //   userUuid: sessionStorage.getItem("userUuid")
      //     ? sessionStorage.getItem("userUuid")
      //     : this.user.userId, // 用户ID/店长ID
      //   // 4D5C642FC29D43C894CC90C4A5122164
      //   promoterUuid: this.orderList.promoterUuid
      //     ? this.orderList.promoterUuid
      //     : "",
      //   totalReferencePrice: totalReferencePrice,
      // };

      // return;
      var inventory = true;
      if (type === 2) {
        let info = this.orderList[0].item;
        let obj = {
          buyCount: info.quantity[0],
          // buyCount: 100,
          commId: info.commId[0],
          seckillInfoCommodityId: info.typeId[0],
          seckillInfoId: "",
        };
        inventory = await this.$API.order
          .secKillTransactionOrder({
            object: obj,
          })
          .then((res) => {
            if (res.data.code !== "SUCCESS") {
              throw new Error("库存不足");
            } else {
              return true;
            }
          })
          .catch((e) => {
            this.show = false;
            this.Toast.fail(e.message);
            return false;
          });
      }
      if (!inventory) return;

      let promoterUuid = "";
      if (
        sessionStorage.getItem("promoterUuid") &&
        sessionStorage.getItem("promoterUuid") !== this.user.userId
      ) {
        promoterUuid = sessionStorage.getItem("promoterUuid");
      }

      this.$API.order
        .TransactionOrder({
          object: {
            address: this.user.detailAddress, //详细地址
            addressId: "", //地址ID
            bankId: bankId, //使用积分银行ID
            bankIntegralAmount: bankIntegralAmount, //银行积分抵扣金额
            city: this.user.city, //城市
            consignee: this.user.nameAddress, //收货联系人
            country: "", //国家
            county: this.user.county, //区县
            freeShipping: freeShipping,
            id: id, //购物车列表ID
            integralAmount: integralAmount, //平台积分金额
            memberName: this.userInfo.userEntity.username, //会员名称
            memberUuid: this.userInfo.id, //会员ID
            orderCommodityItems: orderCommodityItems,
            // penterpriseId: "string",//
            province: this.user.province, //省份
            receivingUnit: "", //收货人单位
            remarks: remarks, //备注
            senterpriseId: senterpriseId, //
            specialPrice: 0, //优惠金额
            storeId: storeId, //店铺ID
            telephone: this.user.phoneAddress, //收货人联系电话
            totalAmount: this.allPrice / 100, //订单总金额
            // totalAmount: 0.01, //订单总金额
            totalQuantity: totalQuantity, //订单总数
            userUuid: sessionStorage.getItem("userUuid")
              ? sessionStorage.getItem("userUuid")
              : this.user.userId, // 用户ID/店长ID
            // 4D5C642FC29D43C894CC90C4A5122164
            promoterUuid: promoterUuid,
            totalReferencePrice: totalReferencePrice,
          },
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            this.$store.dispatch("user/getQuantity");
            if (this.selectIntegral) {
              this.AdjustmentIntegral(res.data.data);
            } else {
              this.show = false;
              this.$router.replace(
                `confirmAnOrder/payForTheOrder?totalDealNumber=${res.data.data.dealNumber}`
              );
            }
          } else {
            this.show = false;
            this.Toast.fail(res.data.msg);
          }
        })
        .catch((err) => {
          this.show = false;
          this.Toast.fail(err);
        });
    },
    async toExchange() {
      let goods = this.orderList[0].orderCardListItems[0];
      let obj = {
        object: {
          address: this.user.detailAddress, //详细地址,
          attributeId: goods.attributeId,
          attributeName: goods.attributeName,
          bankId: this.maxIntegralBank.bankId,
          bankIntegral: this.orderList[0].integral,
          city: this.user.city, //城市
          commId: goods.id,
          consignee: this.user.nameAddress, //收货联系人
          country: "", //国家
          county: this.user.county, //区县
          freeShipping: goods.freeShipping,
          getGoodsAddress: this.tzInfo.userDetailEntity.getGoodsAddress,
          getGoodsName: this.tzInfo.userDetailEntity.getGoodsName,
          memberName: this.userInfo.userEntity.actualName, //会员名称
          memberTelNumber: this.userInfo.userEntity.telNum,
          memberUuid: this.userInfo.id,
          province: this.user.province, //省份
          referencePrice: goods.tradePrice,
          remarks: this.orderList[0].message,
          telephone: this.user.phoneAddress, //收货人联系电话
          totalQuantity: goods.quantity,
          userName: this.tzInfo.userEntity.username, //会员名称
          userTelNumber: this.tzInfo.userEntity.username,
          userUuid: this.tzInfo.id,
        },
      };
      this.$API.order
        .TransactionIntegralOrder(obj)
        .then(async (res) => {
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);

          await this.$store.dispatch("user/getCusBankFilePathList");
          this.show = false;
          this.$router.replace(
            `confirmAnOrder/completionOfPayment?integralDealNumber=${res.data.data.dealNumber}`
          );
        })
        .catch((err) => {
          this.show = false;
          this.Toast.fail(err);
        });
    },
    integralChange(bank) {
      if (this.selectIntegral2) return;

      let select = 0;
      this.bank.forEach((bank) => {
        if (bank.isSelect) select += bank.selectIntegral;
      });

      select = Math.round((this.allPrice2 - select / 100) * 100); //剩余可用积分
      if (bank.bankId === "06D7C96C4ACE485DBDE187A9A0A6FD7F") {
        if (bank.Integral == 0 && bank.Integral.length > 1) bank.Integral = 0;
        if (bank.Integral > bank.integral) {
          if (bank.Integral > select) {
            bank.Integral = select;
          } else {
            bank.Integral = bank.integral;
          }
        } else if (bank.Integral > select) {
          //特殊处理 后需改
          // if (this.bank[0].disabled || this.bank.length === 1) {
          //   bank.Integral = Math.round(this.allPrice2 * 100);
          // } else {
          bank.Integral = select;
          // }
        } else if (bank.Integral < 0) {
          bank.Integral = 0;
        }
      } else {
        // return
        if (Math.round(bank.allPriceIntegral * 100) < select) {
          if (Math.round(bank.allPriceIntegral * 100) > bank.integral) {
            if (bank.Integral > bank.integral) {
              bank.Integral = bank.integral;
            }
          } else {
            if (bank.Integral > Math.round(bank.allPriceIntegral * 100)) {
              bank.Integral = Math.round(bank.allPriceIntegral * 100);
            }
          }
        } else {
          if (bank.Integral > select) {
            bank.Integral = select;
          }
        }
      }
    },
    priceChange(bank) {
      let select = 0;
      this.bank.forEach((bank) => {
        if (bank.isSelect) select += bank.selectIntegral;
      });
      select = Math.round((this.allPrice2 - select / 100) * 100); //剩余可用积分

      if (bank.bankId === "06D7C96C4ACE485DBDE187A9A0A6FD7F") {
        let Price = Math.round(bank.Price * 100);
        if (bank.Price === "-") bank.Price = 0;
        if (bank.Price == 0 && bank.Price.length > 2) bank.Price = "0.0";

        //可用积分 5990    500
        if (select > bank.integral) {
          //用总积分
          if (Price > bank.integral) {
            bank.Price = bank.integral / 100;
          }
        } else {
          //用可用积分
          if (Price > select) {
            bank.Price = select / 100;
          }
        }

        if (Price < 0) {
          bank.Price = 0;
        }
      } else {
        let Price = Math.round(bank.Price * 100);
        if (Math.round(bank.allPriceIntegral * 100) < select) {
          if (Math.round(bank.allPriceIntegral * 100) > bank.integral) {
            if (Price > bank.integral) {
              bank.Price = bank.integral / 100;
            }
          } else {
            if (Price > Math.round(bank.allPriceIntegral * 100)) {
              bank.Price = Math.round(bank.allPriceIntegral * 100) / 100;
            }
          }

          // if (Price > Math.round(bank.allPriceIntegral * 100)) {
          //   bank.Price = Math.round(bank.allPriceIntegral * 100) / 100;
          // }
        } else {
          if (Price > select) {
            bank.Price = select / 100;
          }
        }
      }

      if (bank.Price % 1) {
        bank.Price = String(bank.Price).replace(
          /^(\-)*(\d+)\.(\d\d).*$/,
          "$1$2.$3"
        );
      }
    },
    integralBlur(bank) {
      if (bank.Integral === "") bank.Integral = 0;
      this.selectIntegral = 0;
      this.bank.forEach((bank) => {
        if (bank.priceOrIntegral) {
          this.selectIntegral += Number(bank.Integral);
        } else {
          this.selectIntegral += Math.round(bank.Price * 100);
        }
      });

      bank.selectIntegral = Number(bank.Integral);
    },
    priceBlur(bank) {
      this.selectIntegral = 0;
      this.bank.forEach((bank) => {
        if (bank.isSelect) {
          if (bank.priceOrIntegral) {
            this.selectIntegral += Number(bank.Integral);
          } else {
            this.selectIntegral += Math.round(bank.Price * 100);
          }
        }
      });

      bank.Price = Number(bank.Price).toFixed(2);
      bank.selectIntegral = Math.round(bank.Price * 100);
    },
    integralFocus(bank) {
      bank.Integral = "";
      bank.selectIntegral = Number(bank.Integral);
      this.selectIntegral2 = 0;
    },
    priceFocus(bank) {
      bank.Price = "";
      bank.selectIntegral = Number(bank.Price);
      this.selectIntegral2 = 0;
    },
    radioChange(val) {},
    checkboxChange(list) {
      if (list.disabled) return;
      this.selectIntegral = 0;
      this.bank.forEach((item) => {
        if (item.isSelect) {
          this.selectIntegral += item.selectIntegral;
        }
      });
      let b = this.bank.findIndex((item) => {
        return item.isSelect;
      });
      if (b < 0) {
        this.radio = true;
        this.result = [-1];
      }
      if (this.radio && b >= 0) {
        let i = this.result.findIndex((item) => {
          return item === -1;
        });
        if (i >= 0) this.result.splice(i, 1);
        this.radio = false;
      }
      this.bank.forEach((item) => {
        if (!item.isSelect) {
          item.Integral = 0;
          item.Price = "0.00";
          item.selectIntegral = 0;
        }
      });
    },
    noneCheckbox(val) {
      this.radio = true;
      this.bank.forEach((bank) => {
        bank.isSelect = false;
      });
      this.result = [-1];
      this.selectIntegral = 0;
    },
    all() {
      if (this.priceOrIntegral) {
        if (this.allPrice2 * 10 < this.allIntegral) {
          this.Integral = Math.round(this.allPrice2 * 100);
          // this.Integral = Math.round(this.allPrice2 * 100) - 1;
        } else {
          this.Integral = this.allIntegral;
        }
      } else {
        if (Math.round(this.allPrice2 * 100) < this.allIntegral) {
          this.Price = Number(this.allPrice2).toFixed(2);
          // this.Price = Number(this.allPrice2).toFixed(2) - 0.01;
        } else {
          this.Price = (this.allIntegral / 100).toFixed(2);
        }
      }
    },
    confirm() {
      if (this.radio) {
        this.selectIntegral = 0;
        this.integralShow = false;
        return;
      }
      this.selectIntegral = 0;
      this.bank.forEach((bank) => {
        if (bank.isSelect) {
          if (bank.priceOrIntegral) {
            this.selectIntegral += Number(bank.Integral);
          } else {
            this.selectIntegral += Math.round(bank.Price * 100);
          }
        }
      });
      if (this.selectIntegral === 0) {
        this.radio = true;
        this.bank.forEach((bank) => {
          bank.isSelect = false;
        });
      }
      this.integralShow = false;
    },
    async AdjustmentIntegral(data) {
      //使用积分;
      await this.$API.order.OrderConsumeIntegral({
        object: {
          totalDealNumber: data.dealNumber,
        },
      });
      this.$store.dispatch("user/getCusBankFilePathList");
      if (this.allPrice / 100) {
        //需要支付
        this.show = false;
        this.$router.replace(
          `confirmAnOrder/payForTheOrder?totalDealNumber=${data.dealNumber}`
        );
      } else {
        //使用银行积分抵扣完 不需要支付
        await this.$API.order.updateOrderDealByDealNumber({
          object: {
            attach: "", //附加值
            dealNumber: "", //订单编号
            dealStatus: 8, //交易状态
            payMethod: 11, //支付方式
            payStatus: 1, //支付状态
            totalDealNumber: data.dealNumber, //总订单号
            transactionalNumber: "", //银行流水号
          },
        });
        sessionStorage.removeItem(window.btoa("order"));
        this.show = false;
        this.$router.replace({
          path: "/confirmAnOrder/completionOfPayment",
          query: {
            totalDealNumber: data.dealNumber,
          },
        });
      }
    },
    openIntegral() {
      this.stockpileBank = JSON.stringify(this.bank);
      this.integralCache.radio = this.radio;
      this.integralCache.result = this.result;
      this.integralCache.selectIntegral = this.selectIntegral;
      this.integralCache.selectIntegral2 = this.selectIntegral2;
    },
    closeIntegral() {
      setTimeout(() => {
        this.bank = JSON.parse(this.stockpileBank);
        this.radio = this.integralCache.radio;
        this.result = this.integralCache.result;
        this.selectIntegral = this.integralCache.selectIntegral;
        this.selectIntegral2 = this.integralCache.selectIntegral2;
      }, 300);
    },
    switchover(bank, i) {
      if (i === bank.priceOrIntegral) return;
      bank.priceOrIntegral = i;
      // if (this.priceOrIntegral) {
      //   this.Integral = (this.Price / 10) * 100;
      // } else {
      //   this.Price = (this.Integral / 100).toFixed(2);
      // }
    },

    //切换自提点地址
    switchAddress() {
      let info = {
        type: 2,
        data: {
          id: this.tzInfo.id,
          telNum: this.tzInfo.userEntity.telNum,
          name: this.tzInfo.userEntity.name,
          actualName: this.tzInfo.userEntity.actualName,
          profilePicture: this.tzInfo.userDetailEntity.profilePicture,
          getGoodsAddress: this.tzInfo.userDetailEntity.getGoodsAddress,
          detailAddress: this.tzInfo.userDetailEntity.detailAddress,
          getGoodsPicture: this.tzInfo.userDetailEntity.getGoodsPicture,
          latitudeValue: this.tzInfo.userDetailEntity.latitudeValue,
          longitudeValue: this.tzInfo.userDetailEntity.longitudeValue,
          province: this.tzInfo.userDetailEntity.province,
          city: this.tzInfo.userDetailEntity.city,
          county: this.tzInfo.userDetailEntity.county,
          getGoodsName: this.tzInfo.userDetailEntity.getGoodsName,
          distance: sessionStorage.getItem("distance")
            ? Number(sessionStorage.getItem("distance"))
            : 0,
        },
      };
      console.log(info);
      Android.callAndroid(JSON.stringify(info));
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin box {
  background-color: #fff;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 12px;
}
@mixin price {
  font-size: 16px;
  color: #333333;
  span {
    font-size: 12px;
    font-weight: normal;
    display: inline-block;
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scaleY(0.8);
    position: relative;
    left: 1px;
    top: 1px;
  }
}

.confirm_an_order {
  position: absolute;
  left: 0;
  right: 0;
  top: 46px;
  bottom: 50px;
  max-width: 500px;
  margin: 0 auto;
  background: #f5f5f5;
  overflow-y: scroll;
  padding: 12px 15px;
  padding-bottom: 0;
  .address {
    @include box;
    display: flex;
    align-items: center;
    div {
      flex: 1;
      padding: 0 12px;
      color: #333333;
      b {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        font-size: 14px;
        margin-top: 6px;
      }
    }
    .address_more {
      font-size: 22px;
    }
    .more {
      font-size: 14px;
      color: #999999;
    }
    .svg_more {
      font-size: 12px;
      color: #999999;
    }
  }
  .goods_box {
    @include box;
    .goods_list {
      .store {
        display: flex;
        align-items: center;
        position: relative;
        padding-right: 40px;
        b {
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .svg_store {
          font-size: 12px;
          margin-right: 6px;
          flex-shrink: 0;
        }
        span {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .goods {
        > div {
          display: flex;
          margin-top: 12px;
        }
        .tips {
          font-size: 12px;
          color: #a6a6a8;
          .svg-icon {
            margin-right: 4px;
          }
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 5px;
          margin-right: 12px;
        }
        .details {
          position: relative;
          flex: 1;
          // height: 100px;
          overflow: hidden;
          padding-bottom: 20px;
          h4 {
            line-height: 22px;
            color: #333333;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;
            span {
              color: #fb4e10;
              font-weight: normal;
            }
          }
          p {
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
            color: #999999;
            span {
              color: #333333;
            }
          }
          .attribute {
            margin: 2px 0;
            display: inline-block;
            background: #f1f1f1;
            color: #878787;
            padding: 0 10px;
            line-height: 20px;
            border-radius: 10px;
          }
          .price {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > span {
              color: #999;
              font-size: 12px;
            }
            b {
              @include price;
            }

            // ::v-deep .van-stepper .van-stepper__input {
            //   min-width: 32px;
            // }
          }
        }
      }
    }
    .message {
      margin-top: 12px;
      > div {
        line-height: 20px;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        p {
          color: #666666;
        }
        b {
          @include price;
        }
        span {
          color: #333333;
        }
      }
      ::v-deep .van-cell {
        margin-top: -4px;
        padding: 0;
        margin-bottom: 0;
        background: #f5f5f5;
        padding: 8px;
        border-radius: 2px;
        .van-field__body {
          font-size: 12px;
          line-height: 16px;
          color: #999999;
        }
      }
    }
  }
  .integral {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 15px 12px;
    border-radius: 5px;
    margin-bottom: 12px;
    span {
      color: #666666;
    }
    p {
      display: flex;
      align-items: center;
      span {
        font-weight: bold;
        color: #333333;
      }
      .svg-icon {
        margin-left: 6px;
        font-size: 8px;
      }
    }
  }
  ::v-deep .van-action-sheet {
    min-height: 75%;

    .van-action-sheet__content {
      padding: 0 15px 58px;
      .title {
        text-align: center;
        margin: 10px 0 12px 0;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            color: #333333;
          }
          h3 {
            font-size: 24px;
            line-height: 34px;
            color: #fb4e10;
            span {
              font-size: 12px;
            }
          }
        }
      }
      .van-cell {
        .van-radio__icon {
          height: unset;
          .van-icon {
            width: 18px;
            height: 18px;
          }
        }

        .van-radio__icon--checked {
          .van-icon {
            border: 1px #009a62 solid !important;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .van-icon:before {
            content: "";
            width: 10px;
            height: 10px;
            background: #009a62;
            border-radius: 50%;
          }
        }
      }
      .van-cell--clickable:active {
        background: #fff;
      }
      .use_integral {
        border: 1px solid #c9c9c9;
        border-radius: 4px;
        margin-bottom: 14px;
        padding: 22px 12px;
        .van-cell {
          padding: 0;
          .van-cell__title {
            span:first-child {
              font-size: 16px;
              font-weight: bold;
              color: #333333;
            }
            span:last-child {
              font-size: 12px;
              color: #666666;
            }
            .van-cell__label {
              line-height: 20px;
              color: #f98f10;
            }
          }
        }
        .van-cell::after {
          display: none;
        }

        .modify {
          margin-top: 18px;
          transition: all 0.3s;
          overflow: hidden;
          height: 100px;
          .switchover {
            margin-bottom: 18px;
            color: #333333;
            span:nth-child(2) {
              color: #666;
              margin: 0 4px;
            }
            .select {
              color: #009a4d;
            }
          }
          .modify_input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding-bottom: 10px;
            .van-cell {
              width: 42%;
            }
            input {
              font-size: 24px;
              font-weight: bold;
              color: #333333;
              border: none;
            }
            span {
              font-size: 16px;
              color: #f98f10;
            }
          }
          .modify_input::after {
            position: absolute;
            bottom: 0;
            content: "";
            width: 100%;
            border-bottom: 1px solid #f2f2f2;
            opacity: 1;
          }
          .tips {
            margin-top: 8px;
            font-size: 12px;
            span:nth-child(1) {
              color: #f98f10;
            }
            span:nth-child(2) {
              color: #666666;
            }
            span:nth-child(3) {
              font-weight: bold;
              color: #333333;
            }
          }
        }
        .close {
          margin-top: 0;
          height: 0;
        }
      }
      .do_not_use_integral {
        border: 1px solid #c9c9c9;
        border-radius: 4px;
      }
      .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 4px 15px;
        background: #fff;
      }
    }
  }
  ::v-deep .van-submit-bar {
    max-width: 500px;
    margin: 0 auto;
    right: 0;
  }
  ::v-deep .van-submit-bar__bar {
    padding: 0 15px;

    .van-submit-bar__text {
      .van-submit-bar__price {
        color: #fb4e10;
        font-weight: 600;

        .van-submit-bar__price {
          font-size: 16px;
        }
      }
    }
    .van-button {
      width: 74px;
      height: 38px;
    }
  }

  .van-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper {
      background: #fff;
      width: 70%;
      height: 40%;
      border-radius: 20px;
      padding: 20px;
      padding-bottom: 40px;
      .title {
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        span {
          font-size: 16px;
        }
        .svg-icon {
          color: #666;
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
      .content {
        overflow-y: scroll;
        height: 90%;
        p {
          margin-bottom: 8px;
        }
      }
    }
  }

  ::v-deep .van-overlay {
    background: rgba(51, 51, 51, 0.39);
  }
  .takeTheir_popup {
    border-radius: 5px 5px 0px 0px;
    padding: 0 15px;
    max-height: 85%;
    .title {
      text-align: center;
      margin: 20px 0;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      position: relative;
      .svg-icon {
        position: absolute;
        right: 0;
        top: 3px;
        font-size: 14px;
        color: #878484;
      }
    }
    .content {
      .tips {
        text-align: center;
        p:nth-child(1) {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        p:nth-child(2) {
          font-size: 16px;
          font-weight: bold;
          color: #fb4e10;
          margin: 4px 0;
        }
        p:nth-child(3) {
          font-size: 12px;
          color: #999999;
        }
      }
      .info {
        margin: 32px 0;
        background: #f5f5f5;
        padding: 20px 12px;
        border-radius: 2px;
        h3 {
          font-size: 18px;
          color: #333333;
        }
        h3:nth-child(2) {
          margin: 28px 0 10px 0;
        }
        div {
          display: flex;
          font-size: 14px;
          color: #333333;
          line-height: 22px;
          span {
            flex-shrink: 0;
          }
        }
      }
    }
    .btn {
      display: flex;
      justify-content: space-between;
      padding-bottom: 4px;
      .van-button {
        flex: 1;
      }
      .van-button:nth-child(1) {
        color: #333 !important;
      }
    }
  }
}
</style>
